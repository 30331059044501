.header-nav{
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100%;

  ul, li{
    list-style:none;
    margin:0;
    padding:0;
  }
  ul{
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    height:50px;
  }

  li{
    a{
      cursor:pointer;
      color:white;
      height:50px;
      display:flex;
      justify-content:center;
      align-items:center;
      &.selected{
        border-bottom:2px solid white;
      }
    }

  }

}
