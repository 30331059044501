.tags{
  margin-top:4rem;
}

.tags-list{
  list-style:none;
  padding:0;
  margin:0;
  li{
    display:inline;
    margin-right:1rem;
    margin-bottom:1rem;
    font-style:italic;
    font-family: 'Share Tech Mono', monospace;
  }

}
