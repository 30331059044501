.home-cover{
  background: #1c3b34 url('/bg.png') fixed;
  background-size:cover;
  min-height:50vh;
  display:flex;
  justify-content:center;
  align-items:center;

  color:#fff;
  text-align:center;
  padding-top:200px;
  padding-bottom:8rem;

  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);


  h1{
    margin-top:2rem;
    margin-bottom:2rem;
    line-height:4rem;
    @media(min-width:768px){
      font-size:4rem;
    }
  }

  .cover-text{
    opacity:0;
    transform:translate3d(0,50%,0);
    transition:all 0.5s;
    &.in-view{
      opacity:1;
      transform:translate3d(0,0,0);
    }
  }


}

