@import "../Home/components/HomeCover/home-cover";
.no-route-cover{
  @extend .home-cover;
  width:100%;
}

.no-route-page{
  min-height:1000vh;
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
}
