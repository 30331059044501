.main-header{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  background:rgba(255,255,255,0.1);
  z-index:1;
  transition:background 0.5s;

  &.scrolled{
    background:rgba(0,0,0,0.8);
  }

  .logo{
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:1rem;
    margin-bottom:1rem;
    height:50px;
    max-height:50px;
    img{
      max-height:50px;
    }

    @media(min-width:768px){
      margin-top:0.5rem;
      margin-bottom:0.5rem;
      height:100px;
      max-height:100px;
      img{
        max-height:100px;
      }


    }
  }

}
