.contact-page{
  background-color:white;
  background-position:bottom right;
  background-repeat:no-repeat;
  padding-top:8rem;
  padding-bottom:8rem;
  clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
  align-items: center;

  position:relative;
  transform:translate3d(100%,0,0);
  transition: transform 0.3s;

  overflow:hidden;

  &:before{
    background: rgb(254,227,143);
    background: linear-gradient(25deg, rgba(254,227,143,0.9) 0%, rgba(253,187,45,0.9) 100%);
    position:absolute;
    top:0;
    height:100%;
    width:100%;
    content:"";
    z-index:-1;
  }


  &.in-view {
    transform: translate3d(0, 0, 0);
  }
}
