.main-footer{
  width:100%;
  //background:#333;
  color:#333;
  display:flex;
  justify-content:flex-end;
  align-items:flex-end;
  padding:1rem;

}
